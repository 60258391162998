/** @jsxImportSource @emotion/react */
import { Box, Flex } from "../FlexBox";
import { SearchBox } from "react-instantsearch-dom";
import { css } from "@emotion/react";
import { useTheme } from "@emotion/react";
import { BsSearch } from "react-icons/bs";

function SearchLabel() {

	const theme = useTheme();

	return <Flex
		bg="brand1"
		alignItems="center"
		p={3}
		color="#ffffff"
		fontSize="1.4rem"
		fontFamily={theme.fonts[0]}
		css={css`position: relative;`}
	>
		<div css={css`
			height: 39px;
			width: 39px;
			background-color: ${theme.colors.brand1};
			transform-origin: center;
			transform: rotate(45deg);
			position: absolute;
			display: block;
			right: -19px;
			top: 8px;
		`} />
		<Box pr={2} fontSize="0px">
			<BsSearch fontSize="1.2rem" />
		</Box>
		<Box fontWeight="bold" fontSize="1.2rem" css={css`z-index: 1;`}>
			Search
		</Box>
	</Flex >
}

export default function SearchBar() {
	const theme = useTheme();
	return <Flex width="100%" alignItems="center">
		<Box mr={6} fontSize="0px">
			<SearchLabel />
		</Box>
		<Box flex="auto" css={css`
			input {
				width: 100%;
				outline: 0;
				padding: ${theme.space[3]};
				font-size: 1.2rem;
				border: 5px solid ${theme.colors.grey2};
				color: ${theme.colors.brand3};
				font-style: italic;
			}
			button, .ais-SearchBox-loadingIndicator {
				display: none;
			} 
		`}>
			<SearchBox
				autoFocus
				placeholder="Find schedule items.."
				onFocus={e => e.target.select()}
			/>
		</Box>
	</Flex>
}
