/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { css } from "@emotion/react";
import React, { useEffect, useRef } from "react";
import { useContext } from "react";
import { Highlight, connectInfiniteHits } from "react-instantsearch-dom";
import { Box, Flex } from "../FlexBox";
import { ScheduleContext } from "../layouts/ViewerLayout";
import ScheduleItem from "./ScheduleItem";

export const highlightCss = props => css`
	.ais-Highlight-highlighted {
		background-color: #fffb92;
		font-weight: bold;
	}
`;

function Level1Heading({ hit, isComposite }) {
	return <Box width="100%" p={3} bg="grey2" fontWeight="bold" fontSize="1.4rem">
		{hit.level1Code ? hit.level1Code + ": " : ""}{isComposite ? hit.sectionDesc : hit.level1Desc}
	</Box>
}

function Level2Heading({ hit, isComposite }) {
	const theme = useTheme();
	return <Flex width="100%" p={2} fontWeight="bold" fontSize="1.2rem" css={[css`
		border-bottom: 2px solid ${theme.colors.grey2};
	`, highlightCss()]}>
		<Box p={2}>
			{hit.level2Code}
		</Box>
		<Box p={2}>
			{isComposite
				? <Highlight tagName="mark" hit={hit} attribute="level1Desc" />
				: <Highlight tagName="mark" hit={hit} attribute="level2Desc" />
			}
		</Box>
	</Flex>
}

export default connectInfiniteHits(function ScheduleDataHits({ hits, hasMore, refineNext,showPdf,setShowPdf,showPdfFile }) {

	const theme = useTheme();
	const { schedules } = useContext(ScheduleContext);
	const thresholdElement = useRef(null);
	const lastResult = useRef(null);
	
	// console.log("hits",hits)	

	useEffect(() => {
		try {
			const observer = new IntersectionObserver(events => {
				const visible = Boolean(events[0].intersectionRatio);
				if (visible && hasMore) {
					refineNext();
				}
			}, {
				threshold: [0, 1]
			});
			if (thresholdElement.current) observer.observe(thresholdElement.current);
			return () => observer.disconnect();
		} catch (error) {
			console.warn("IntersectionObserver not supported.");
		}
	}, [refineNext, hasMore, hits.length]);

	return <>

		{/* Message if no results */}
		{!hits.length && <Box fontSize="1.2rem" opacity={0.4} py={7} textAlign="center">
			Nothing found..
		</Box>}
		{/* {console.log("hits",hits)} */}
		{/* Render out the hits. */}
		{hits.map((hit, index) => {
			const schedule = schedules.find(schedule => Number(schedule.ref) === hit.schedule);
			// console.log("shedule",schedule)
			// Check if composite.
			const isComposite = schedule?.isComposite;
			// Check for changes of category.
			const level1Change = index === 0 || lastResult.current.level1Code !== hit.level1Code;
			const level2Change = index === 0 || lastResult.current.level2Code !== hit.level2Code;

			lastResult.current = hit;
			return <React.Fragment key={hit.objectID}>
				{/* Render headings if the category has changed. */}
				{level1Change && <Level1Heading isComposite={isComposite} hit={hit} />}
				{level2Change && <Level2Heading isComposite={isComposite} hit={hit} />}
				{/* Render the item iteself. */}
				<ScheduleItem hit={hit} schedule={schedule} showPdf={showPdf} setShowPdf={setShowPdf} showPdfFile={showPdfFile} />
			</React.Fragment>
		})}

		{/* Element to trigger loading more. */}
		<Box width="100%" p={3} ref={thresholdElement}>
			<button
				disabled={!hasMore}
				onClick={e => {
					if (hasMore) refineNext();
				}}
				css={css`
					width: 100%;
					padding: ${theme.space[3]};
					border: none;
					background: ${theme.colors.brand3};
					font-weight: bold;
					font-family: ${theme.fonts[0]};
					font-size: 1.2rem;
					color: #ffffff;
					cursor: pointer;
					&:disabled {
						opacity: 0.3;
					}
				`}
			>Show more</button>
		</Box>

		<Box width="100%" pb={5} />
	</>
});
