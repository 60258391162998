import React from 'react'
import { useEffect } from 'react'
import { Flex } from 'reflexbox'
import { WP_ROOT } from '../constants'

const Logout = () => {

   useEffect(()=>{
        window.location.href = `${WP_ROOT}/wp-login.php?action=logout`
   },[])
  return (
    <Flex
    width="100%"
    alignItems="center"
    justifyContent="center"
    mt="20vh"
    p={3}
>
  <h3>Logging out .... </h3>
</Flex>
  )
}

export default Logout
