/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { useTheme } from "@emotion/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Box, Flex } from "../components/FlexBox";
import AccountLayout from "../components/layouts/AccountLayout";
import { OAUTH_CLIENT_ID, WP_ROOT } from "../constants"

// Build the oauth url.
const oauthParams = new URLSearchParams({
	client_id: OAUTH_CLIENT_ID,
	response_type: "code"
});
const oauthRoot = 
`${WP_ROOT}/oauth/authorize?${oauthParams.toString()}`;

const LoginButton = styled.button`
	width: 100%;
	display: block;
	background-color: ${props => props.theme.colors.brand3};
	color: #ffffff;
	padding: ${props => props.theme.space[3]};
	font-size: 1.2rem;
	font-family: "Roboto Slab";
	font-weight: bold;
	cursor: pointer;
`;

const Panel = styled(Flex)`
	background-color: #ffffff;
	box-shadow: 0px 4px 4px #0000004f;
    border-bottom: 5px solid #00000017;
    border-top: 3px solid ${props => props.theme.colors.brand2};
`;

export default function Login() {

	const theme = useTheme();
	const [loading, setLoading] = useState(false);

	return <AccountLayout>
		<Flex
			width="100%"
			alignItems="center"
			justifyContent="center"
			mt="20vh"
			p={3}
		>
			<Panel
				width="100%"
				maxWidth="400px"
				p={2}
				flexDirection="column"
				textAlign="center"
			>
				<Box p={2} fontSize="1.6rem" fontFamily="Roboto Slab">
					Log in with your <b css={css`
						color: ${theme.colors.brand2};
					`}>NSR</b> account
				</Box>
				<Box p={2}>
					You will be redirected back here once verified.
				</Box>
				<Box p={2} style={{ display:'flex',flexDirection:'column'}}>
					{!loading ?
						<a href={oauthRoot} >
							<LoginButton onClick={e => setLoading(true)}>
								Log in
							</LoginButton>
						</a>
						:
						<LoginButton disabled={true}>Loading..</LoginButton>
					}
					{/* <a href="/demo-viewer" style={{textDecoration:'none',marginTop:'6px',padding:'16px 0px',background:'gray',color:'white',fontSize:'16px'}}>Demo</a> */}
				</Box>
			</Panel>
		</Flex>
	</AccountLayout>
}
