/** @jsxImportSource @emotion/react */
import algoliasearch from "algoliasearch/lite";
import { createContext, useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { InstantSearch } from "react-instantsearch-dom";
import { ALGOLIA_APP, API_ROOT } from "../../constants";
import { UserContext } from "../../context";
import { Box, Flex } from "../FlexBox";
import AccountLayout from "./AccountLayout";
import axios from "axios";
import { SchedulesJson } from "../../data/schedule";

const INDEX_NAME = "scheduleData";

async function getSearchData({ token }) {
	const response = await axios.get(`${API_ROOT}/active-products`, {
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	return {
		key: response.data.searchKey,
		schedules: response.data.products
	};
}

export const ScheduleContext = createContext({
	schedules: [],
});


const DATA = [{"key":"1323_1495","wpProductId":1323,"wpVariantId":1495,"name":"Box Set 1 - eBook","year":"2022\/ 23","version":"Standard","type":"eBook","image":"https:\/\/nsrm.webchoicetest.com\/wp-content\/uploads\/2022\/07\/Building-works-image.jpg"},{"key":"1416_1489","wpProductId":1416,"wpVariantId":1489,"name":"Box Set 1 - eBook","year":"2022\/ 23","version":"Standard","type":"eBook","image":"https:\/\/nsrm.webchoicetest.com\/wp-content\/uploads\/2022\/07\/Electrical-works-image.jpg"},{"key":"1406_1492","wpProductId":1406,"wpVariantId":1492,"name":"Box Set 1 - eBook","year":"2022\/ 23","version":"Standard","type":"eBook","image":"https:\/\/nsrm.webchoicetest.com\/wp-content\/uploads\/2022\/07\/Mechanical-Services-image.jpg"}]


export default function withViewerLayout(Component) {
	return function (props) {

		const [algoliaKey, setAlgoliaKey] = useState(null);
		const [schedules, setSchedules] = useState([]);
		const [searchClient, setSearchClient] = useState(null);
		// const searchState = useRef(null);
		const [searchState, setSearchState] = useState({});
		const { token } = useContext(UserContext);

		// Request an algolia key and product information on mount.
		useEffect(() => {
			let interval;
			if (token) {
				async function getData() {
					try {
						const { key, schedules } = await getSearchData({ token });
						setAlgoliaKey(key);					
						setSchedules(schedules);
						// console.log(schedules);
						
					} catch (error) {
						console.error("Couldn't get key..", error);
					}
				}
				getData();
				// Also request every 55 seconds because the key expires.
				interval = setInterval(getData, 55000);
			}else{
                //  setSchedules(SchedulesJson);
				//  console.log(schedules);
			};
			// return () => clearInterval(interval);
		}, [token]);

		// Construct an algolia client if there is a key present.
		useEffect(() => {
			if (algoliaKey) {
				const client = algoliasearch(ALGOLIA_APP, algoliaKey);
				// client.addApiKey
				setSearchClient(client);
			}
		}, [algoliaKey]);

		return <AccountLayout withAdminLink>
			<ScheduleContext.Provider value={{ schedules }}>
				<Flex
					width="100%"
					backgroundColor="bg1"
					p={7}
					fontFamily="Roboto Slab"
					justifyContent="center"
				>
					<Flex width="100%" maxWidth="1100px" flexDirection="column">
						<Box width="100%" color="brand2" fontWeight={700} fontSize="1.75rem">
							NSR
						</Box>
						<Box width="100%" color="brand3" fontWeight={400} fontSize="3.5rem">
							Schedule Viewer
						</Box>
					</Flex>
				</Flex>
        <section>
          {Boolean(algoliaKey && searchClient) ?
            <InstantSearch
              searchClient={searchClient}
              indexName={INDEX_NAME}
              initialUiState={searchState.current || undefined}
              searchState={searchState}
              onSearchStateChange={setSearchState}
            >
              <Component
                {...props}
                algoliaKey={algoliaKey}
                searchClient={searchClient}
              />
            </InstantSearch>
            :
            <Flex width="100%" textAlign="center" flexDirection="column">
              <Box>Loading..</Box>
            </Flex>
          }
        </section>
			</ScheduleContext.Provider>
		</AccountLayout>
	}
}
