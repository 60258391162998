/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Highlight } from "react-instantsearch-dom";
import { ImgIcon } from "..";
import { Box, Flex } from "../FlexBox";
import { highlightCss } from "./ScheduleDataHits";
import { Button } from "..";

const ResultData = styled(Box)`
	font-weight: bold;
	color: ${props => props.theme.colors[props.color || "brand1"]};
	padding: ${props => props.theme.space[2]};
	text-align: center;
	width: 8%;
	min-width: 8%;
  filter: blur(${props => props.blur ? '5px' : '0px'})
`;

export default function ScheduleItem({ hit, schedule, showPdf, setShowPdf, showPdfFile }) {

	const theme = useTheme();

	const resultCode = hit.level3Code.replace(hit.level2Code, "");

	return <Flex width="100%" fontSize="1.2rem" p={2} css={[css`
		border-bottom: 2px solid ${theme.colors.grey2};
	`, highlightCss()]}>
		<Box px={2} fontSize="0px">
			<ImgIcon
				src={schedule?.image}
				size={50}
			/>
		</Box>
		<ResultData>
			{resultCode}
		</ResultData>
		<Box p={2} flex="auto">
			{Boolean(hit.alternativeCode) &&
				<span className="alternative-code">{hit.alternativeCode} - </span>
			}
			{schedule?.isComposite && <span>
				<Highlight hit={hit} attribute="level2Desc" />
				<span> - </span>
			</span>}
			<Highlight hit={hit} attribute="level3Desc" />
		</Box>
		<Box fontSize="0px">

			{showPdf ? (
				<Button fontSize="1rem" css={[css`display: flex; align-items: center;`]}>
					<img src="./PDF Download.png" css={[css`width: 24px; margin-right: 8px`]} /> PDF
				</Button>
			) : ""
			}

		</Box>

		
		{/* {
			showPdf ?
				" " : (
					<>

						<ResultData>{hit.measurement}</ResultData>
						<ResultData>{hit.material}</ResultData>
			<ResultData >{hit.labour}</ResultData>
			<ResultData>{hit.plant}</ResultData>
			<ResultData color="brand">{hit.total}</ResultData>
						<ResultData blur>***</ResultData>
						<ResultData blur>***</ResultData>
						<ResultData blur>***</ResultData>
						<ResultData color="brand" blur>***</ResultData>

					</>

				)

		} */}



		<ResultData>{hit.measurement}</ResultData>
		<ResultData>{hit.material}</ResultData>
		<ResultData >{hit.labour}</ResultData>
		<ResultData>{hit.plant}</ResultData>
		<ResultData color="brand">{hit.total}</ResultData>
		{/* <ResultData blur>***</ResultData>
		<ResultData blur>***</ResultData>
		<ResultData blur>***</ResultData>
		<ResultData color="brand" blur>***</ResultData> */}


	</Flex>
}
