import React, { useState } from "react";
import "./demo.css";
import { Box } from "../components/FlexBox";
import { Button } from "../components";
import { useTheme } from "@emotion/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { SchedulesJson } from "../data/schedule";
import DemoScheduleItem from "./demo/demoScheduleItem";

const ResultData = styled(Box)`
	font-weight: bold;
	color: ${props => props.theme.colors[props.color || "brand1"]};
	padding: ${props => props.theme.space[2]};
	text-align: center;
	width: 8%;
	min-width: 8%;
  filter: blur(${props => props.blur ? '5px' : '0px'})
`;

export default function Login() {
  const [showPdf, setShowPdf] = useState(false);
  const [query,setQuery] = useState("");

  const showPdfFile = () => {
    setShowPdf(!showPdf);
  };

  // const keys = ["sectionCode","level1Desc","level2Code","level2Desc","level3Code","level3Desc","alternativeCode","total","labour"]

  // console.log(SchedulesJson[0]["level3Desc"]);

  // const search = (data) => {
  //   return data.filter((item) => 
  //     keys.some((key) => item[key].includes(query))
  //   )
  // }
  return (
    <div>
      <div class="css-1rwc17d"></div>
      <div class="css-1qcml3q">
        <div class="css-vurnku">
          <a href="https://nsrm.co.uk">
            <span class="css-vaulk-ImgIcon"></span>
          </a>
        </div>
        <div class="css-1behg4x">
          <div class="css-sskaa1">
            Logged in as <b class="css-1qpry6n-AccountLayout">Demo User</b>
          </div>
          {/* <div class="css-kz6ioj">
            <a href="/admin">
              <button class="css-qdtep2">Go to Admin</button>
            </a>
          </div>
          <div class="css-1w070cj">
            <button class="css-qdtep2">Log out 4</button>
          </div> */}
        </div>
      </div>

      <div class="css-12vj5sp">
        <div class="css-1mb6ai6">
          <div class="css-1vj6xes">NSR</div>
          <div class="css-lu6j54">Schedule Viewer</div>
        </div>
      </div>

      <section>
        <div class="css-1usr1wh">
          <div class="css-1tkx55p">
            <div class="css-14mgz8m-ScheduleFilters css-c24bqj">
              <div class="css-1osjthh">Schedule / Section</div>
              <div class="css-h9fdzh">
                <div class="ais-HierarchicalMenu">
                  <ul class="ais-HierarchicalMenu-list">
                    <li class="ais-HierarchicalMenu-item">
                      <a class="ais-HierarchicalMenu-link" href="#">
                        <span class="ais-HierarchicalMenu-label">
                          Housing Maintenance 2021
                        </span>{" "}
                        <span class="ais-HierarchicalMenu-count">1161</span>
                      </a>
                    </li>
                    <li class="ais-HierarchicalMenu-item">
                      <a class="ais-HierarchicalMenu-link" href="#">
                        <span class="ais-HierarchicalMenu-label">
                          Building Works 2022
                        </span>{" "}
                        <span class="ais-HierarchicalMenu-count">12005</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="css-okn6en">Year</div>
              <div class="css-okga05-YearSelector css-sg489d">
                <div class="ais-RefinementList">
                  <ul class="ais-RefinementList-list">
                    <li class="ais-RefinementList-item">
                      <label class="ais-RefinementList-label">
                        <input
                          class="ais-RefinementList-checkbox"
                          type="checkbox"
                        />
                        <span class="ais-RefinementList-labelText">2022</span>{" "}
                        <span class="ais-RefinementList-count">12,005</span>
                      </label>
                    </li>
                    <li class="ais-RefinementList-item">
                      <label class="ais-RefinementList-label">
                        <input
                          class="ais-RefinementList-checkbox"
                          type="checkbox"
                        />
                        <span class="ais-RefinementList-labelText">2021</span>{" "}
                        <span class="ais-RefinementList-count">1,161</span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="css-ecz0zi">
            <div class="css-h9fdzh">
              <div class="css-j0oxed">
                <div class="css-uee49b">
                  <div class="css-2qgr2-SearchLabel css-marnar">
                    <div class="css-1yza6mu-SearchLabel"></div>
                    <div class="css-q3upwa">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 16 16"
                        font-size="1.2rem"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                      </svg>
                    </div>
                    <div class="css-14p96h-SearchLabel css-yd7lbe">Search</div>
                  </div>
                </div>
                <div class="css-13lplv1-SearchBar css-44mn3c">
                  <div class="ais-SearchBox">
                    <form
                      novalidate=""
                      class="ais-SearchBox-form"
                      action=""
                      role="search"
                    >
                      <input
                        type="search"
                        placeholder="Search here…"
                        autocomplete="off"
                        autocorrect="off"
                        autocapitalize="off"
                        spellcheck="false"
                    
                        maxlength="512"
                        class="ais-SearchBox-input"
                 
                        onChange={(e)=> setQuery(e.target.value)}
                      />
                      <button
                        type="submit"
                        title="Submit your search query."
                        class="ais-SearchBox-submit"
                      >
                        <svg
                          class="ais-SearchBox-submitIcon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 40 40"
                        >
                          <path d="M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z"></path>
                        </svg>
                      </button>
                      <button
                        type="reset"
                        title="Clear the search query."
                        class="ais-SearchBox-reset"
                        hidden=""
                      >
                        <svg
                          class="ais-SearchBox-resetIcon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          width="10"
                          height="10"
                        >
                          <path d="M8.114 10L.944 2.83 0 1.885 1.886 0l.943.943L10 8.113l7.17-7.17.944-.943L20 1.886l-.943.943-7.17 7.17 7.17 7.17.943.944L18.114 20l-.943-.943-7.17-7.17-7.17 7.17-.944.943L0 18.114l.943-.943L8.113 10z"></path>
                        </svg>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="show-pdf">
              <button onClick={showPdfFile} className="show-pdf-button">
                {showPdf ? "Hide Pdf" : "Show Pdf"}
              </button>
            </div> */}
            {showPdf ? (
              ""
            ) : (
              <>
                <div class="css-tzaj1n">
                  <div class="css-ik0lim">
                    Mat
                    <br />£
                  </div>
                  <div class="css-ik0lim">
                    Lab
                    <br />£
                  </div>
                  <div class="css-ik0lim">
                    Plant
                    <br />£
                  </div>
                  <div class="css-97ef8e">
                    Total
                    <br />£
                  </div>
                </div>
              </>
            )}
            <div class="css-vurnku">
              <div class="css-1sl4ywr">TEMPORARY WORKS</div>

              {/* // const keys = ["sectionCode","level1Desc","level2Code","level2Desc","level3Code","level3Desc","alternativeCode","total","labour"] */}

              {SchedulesJson.filter((item)=> item.level2Desc.toLowerCase().includes(query) ||
               item.sectionCode.toLowerCase().includes(query) ||
               item.level1Desc.toLowerCase().includes(query) || 
               item.level2Code.toLowerCase().includes(query) || 
               item.level3Code.toLowerCase().includes(query) || 
               item.level3Desc.toLowerCase().includes(query) || 
               item.alternativeCode.toLowerCase().includes(query)).map((item)=>(
                <DemoScheduleItem item={item} SchedulesJson={SchedulesJson} showPdf={showPdf} />
              ))}
              

               
              <div class="css-4mjwrc">
                {/* <button class="css-up7fix-ScheduleDataHits">Show more</button> */}
              </div>
              <div class="css-1am6gcz"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
