import React from "react";
import { SchedulesJson } from "../../data/schedule";
import "../demo.css";
import { Box } from "../../components/FlexBox";
import { Button } from "../../components";
import { useTheme } from "@emotion/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
const ResultData = styled(Box)`
	font-weight: bold;
	color: ${props => props.theme.colors[props.color || "brand1"]};
	padding: ${props => props.theme.space[2]};
	text-align: center;
	width: 8%;
	min-width: 8%;
  filter: blur(${props => props.blur ? '5px' : '0px'})
`;

export default function DemoScheduleItem({ item,showPdf }) {
//   const filteredArray = SchedulesJson.filter((obj, index, arr) => {
//     return index === arr.findIndex((o) => o.level2Code !== obj.level2Code);
//   });

  let filteredArray = SchedulesJson.filter(s => s.level2Code === item.level2Code);

  const resultCode = item.level3Code.replace(item.level2Code, "");

  return (

    <>
        
        <div class="css-8k31p5-Level2Heading css-vdinwm" key={item._id}>
                <div class="css-h9fdzh">{item.level2Code}</div>
                <div class="css-h9fdzh">
                  <span class="ais-Highlight">
                    <span class="ais-Highlight-nonHighlighted">
                      {item.level1Desc}
                    </span>
                  </span>
                </div>
              </div>
              <div class="css-1xy42d1-ScheduleItem css-jusk0a">
                <div class="css-1konojr">
                  <span class="css-rerot2-ImgIcon"></span>
                </div>
                <div class="css-bjf42r css-vurnku">{item.level3Code.replace(item.level2Code, "")}</div>
                <div class="css-1pkgl05">
                  <span class="alternative-code">{item.alternativeCode} - </span>
                  <span>
                    <span class="ais-Highlight">
                      <span class="ais-Highlight-nonHighlighted">
                      {item.level2Desc}
                      </span>
                    </span>
                    <span> - </span>
                  </span>
                  <span class="ais-Highlight">
                    <span class="ais-Highlight-nonHighlighted">
                      {item.level3Desc}
                    </span>
                  </span>
                </div>
                <Box fontSize="0px">
                  {showPdf ? (
                    <Button
                      fontSize="1rem"
                      css={[
                        css`
                          display: flex;
                          align-items: center;
                        `,
                      ]}
                    >
                      <img
                        src="./PDF Download.png"
                        style={{ width:'20px',height:'20px',objectFit:"cover"}}
                        css={[
                          css`
                            width: 24px;
                            margin-right: 8px;
                          `,
                        ]}
                      />{" "}
                      PDF
                    </Button>
                  ) : (
                    ""
                  )}
                </Box>
                {showPdf ? (
                  " "
                ) : (
                  <>
                    <ResultData>{item.measurement}</ResultData>
                    {/* <ResultData>{hit.material}</ResultData>
			<ResultData >{hit.labour}</ResultData>
			<ResultData>{hit.plant}</ResultData>
			<ResultData color="brand">{hit.total}</ResultData> */}
                    <ResultData blur>***</ResultData>
                    <ResultData blur>***</ResultData>
                    <ResultData blur>***</ResultData>
                    <ResultData color="brand" blur>
                      ***
                    </ResultData>
                  </>
                )}
              </div>
     
   
    </>
    
  );
}
