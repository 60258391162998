import React, { useContext } from 'react'
import { ScheduleContext } from '../layouts/ViewerLayout';
import { Box } from '../FlexBox';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';

export default connectInfiniteHits(function PdfViewer ({hits}) {

	const { schedules } = useContext(ScheduleContext);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const [selectedShedule,setSelectedShedule] = useState("");

  const[isPdfAvailable,setIsPdfAvailable]= useState(true);

  useEffect(()=>{
    {hits.map((hit, index) => {
			const schedule = schedules.find(schedule => Number(schedule.ref) === hit.schedule)
      setSelectedShedule(schedule);
    })}
    if(selectedShedule){
      axios.get(`https://cdn.app.nsrm.co.uk/${selectedShedule?.scheduleData?.id}`).then((res)=>{setIsPdfAvailable(true)}).catch((err)=>{setIsPdfAvailable(false)})
    }
  },[selectedShedule])
  
  return (
    <Box p={5} bg="#fff" width="100%" height="100%">
      {isPdfAvailable ?<Viewer
        fileUrl={`https://cdn.app.nsrm.co.uk/${selectedShedule?.scheduleData?.id}`}
        plugins={[
        // Register plugins
        defaultLayoutPluginInstance,
        ]}
        />:<h3>Guideline is currently unavailable</h3>}
    </Box>
  )
})


