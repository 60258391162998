/** @jsxImportSource @emotion/react */
import axios from "axios";
import { useContext } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { Button, ImgIcon } from "..";
import { API_ROOT } from "../../constants";
import { UserContext } from "../../context";
import { Box, Flex } from "../FlexBox";
import _ from "lodash";
import { css } from "@emotion/react";
import { useTheme } from "@emotion/react";

import { Uploader } from "uploader"; // Installed by "react-uploader".
import { UploadButton } from "react-uploader";
import { uploadFile } from "../../services/api";

const uploader = Uploader({
  apiKey: "free", // Get production API keys from Upload.io
});

const options = { multi: true };

async function getProducts(token) {
  try {
    const response = await axios.get(`${API_ROOT}/products`, {
      params: {
        type: ["eBook", "sfg20", "smv"].join(","),
        showHidden: "true",
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.products;
  } catch (error) {
    console.error("Couldn't get products:", error);
    throw new Error(error.message);
  }
}

export default function ProductList() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token } = useContext(UserContext);

  useEffect(() => {
    setLoading(true);
    if (token)
      (async () => {
        try {
          const products = await getProducts(token);
          setProducts(products);
        } catch (error) {
          console.error("Couldn't get products:", error);
        } finally {
          setLoading(false);
        }
      })();
  }, [token]);

  const scheduleTypes = useMemo(() => {
    const filtered = products
      .filter((product) => product.scheduleType)
      .sort((a, b) => b.year - a.year);
    return _.groupBy(filtered, (product) => product.scheduleType);
  }, [products]);

  return (
    <>
      {loading && (
        <Box width="100%" p={3}>
          Loading..
        </Box>
      )}
      {Object.entries(scheduleTypes).map(([title, items]) => (
        <Schedule title={title} items={items} key={title} />
      ))}
    </>
  );
}

function Schedule({ title, items }) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  return (
    <Flex flexDirection="column">
      <Flex
        flexWrap="wrap"
        onClick={(e) => setOpen(!open)}
        css={css`
          cursor: pointer;
          &:hover {
            background-color: ${theme.colors.brand2};
          }
        `}
      >
        <Box
          flex="auto"
          fontSize="2.4rem"
          fontFamily={0}
          fontWeight="bold"
          p={3}
          pb={1}
        >
          {title}
        </Box>
        <Box
          fontFamily="monospace"
          fontSize="2.4rem"
          p={3}
          css={
            open &&
            css`
              transform: rotate(180deg);
            `
          }
        >
          &#9660;
        </Box>
      </Flex>

      <Box fontSize="1.6rem" fontFamily={0} p={3} pt={0}>
        {items.length} items
      </Box>
      {open && (
        <Flex flexWrap="wrap" p={2} pt={0}>
          {items.map((product) => {
            return (
              <Box
                p={2}
                width={["100%", "100%", "100%", "50%"]}
                key={product.ref}
              >
                <ScheduleItem product={product} />
              </Box>
            );
          })}
        </Flex>
      )}
    </Flex>
  );
}

function ScheduleItem({ product }) {
  const [syncing, setSyncing] = useState(false);
  const { token } = useContext(UserContext);

  async function onSync() {
    if (
      !window.confirm(
        "Are you sure you want to sync this schedule? This will load data into Algolia."
      )
    )
      return;
    try {
      setSyncing(true);
      await axios.get(`${API_ROOT}/sync/${product.ref}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(product);
    } catch (error) {
      console.error("Error syncing:", error);
    } finally {
      setSyncing(false);
    }
  }


  const [file, setFile] = useState("");
  const [result, setResult] = useState("");
  const [productId, setProductId] = useState(product.id);
  const[isPdfSelected,setIsPdfSelected] = useState(false);


  const filePdfUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("productId", productId);

    try {
      const response = await axios.post(`${API_ROOT}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // console.log("File uploaded successfully:", response.data);
      alert("File uploaded successfully")
      // Do something with the response if needed
    } catch (error) {
      console.error("Error uploading file:", error);
      // Handle the error appropriately
      alert("Error uploading file")
    }
  };

  return (
    <Flex
      key={product.key}
      bg="#ffffff"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box fontSize="0px">
        <ImgIcon src={product.data.img} size={40} />
      </Box>
      <Box p={2} fontFamily={0}>
        {/* <a href={`https://cdn.app.nsrm.co.uk/${productId}`} target="_blank"> */}
        {product.scheduleType}
        {/* </a> */}
      </Box>
      <Box p={2} fontFamily={0} fontWeight="bold">
        {product.version}
      </Box>
      <Box p={2} fontFamily={0}>
        {product.year}
      </Box>
      <Box fontSize="0px">
        <Button onClick={onSync} fontSize="1rem" disabled={syncing}>
          {syncing ? "..." : "Sync"}
        </Button>
      </Box>
      <Box><label
              htmlFor={productId}
              style={{ padding: 8, cursor: "pointer" }}
            >
              <img
                src={!isPdfSelected ?"./PDF Upload.png": "./checked.png"}
                css={[
                  css`
                    width: 24px;
                    margin-right: 8px;
                  `,
                ]}
              />{" "}
           
            </label>
            <input
              type="hidden"
              name=""
              id=""
              value={productId}
              onChange={(e) => {setProductId(e.target.value)}}
            />
            <input
              style={{ display: "none" }}
              type="file"
              name="file"
              id={productId}
              onChange={(e) => {setFile(e.target.files[0]);setIsPdfSelected(true);alert("The PDF is selected, now please click “Upload PDF to complete the upload process")}}
            /></Box>
      <Box fontSize="0px">
        {/* <UploadButton 
        uploader={uploader}
        options={options}
        onComplete={files => alert(files.map(x => x.fileUrl).join("\n"))}>
          {({onClick}) =>
            <Button onClick={onClick} fontSize="1rem" css={[css`display: flex; align-items: center;`]}>
              <img src="./PDF Upload.png" css={[css`width: 24px; margin-right: 8px`]} /> PDF
            </Button>
          }
      </UploadButton> */}

        <form onSubmit={filePdfUpload}>
          {/* <Button
            fontSize="1rem"
            css={[
              css`
                display: flex;
                align-items: center;
                padding: 0;
              `,
            ]}
          > */}
            
          {/* </Button> */}
          {/* <Button
            fontSize="1rem"
            css={[
              css`
                display: flex;
                align-items: center;
              `,
            ]}
            
          >
            Upload PDF
          </Button> */}
          <button style={{display:'flex',alignItems:'center',fontSize:'1rem'}} type="submit">Upload PDF</button>
        </form>
      </Box>
    </Flex>
  );
}
