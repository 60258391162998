import { css } from "@emotion/react";

export const XS = 576,
	SM = 768,
	MD = 992,
	LG = 1200,
	XL = 1500,
	XXL = 1800;

export const breakpoints = {
	XS,
	SM,
	MD,
	LG,
	XL,
	XXL,
}

export const breaks = {
	xs: "576px",
	sm: "768px",
	md: "992px",
	lg: "1200px",
	xl: "1500px",
	xxl: "1800px",
}

export const scrollbarStyles = theme => css`
	${theme.media.md} {
		/* Track */
		&::-webkit-scrollbar {
			width: 8px;
			background: #00000012;
		}
		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: #ffffffb8;
			/* border-radius: 10px; */
		}
		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: #ffffff;
			cursor: pointer;
		}
	}
`;

const theme = {
	// Expose the raw breakpoints to the theme.
	breakpoints: Object.values(breaks),
	// Create media rules for each breakpoint.
	media: (() => {
		const rules = {};
		Object.entries(breaks).forEach(([name, value]) => {
			rules[name] = `@media (min-width: ${value})`;
		});
		return rules;
	})(),
	// System wide colour palette.
	colors: {
		bg1: "#f0fafe",
		brand1: "#3473b0",
		brand2: "#41bcea",
		brand3: "#140e41",
		grey1: "#e6e6e6",
		grey2: "#f2f2f2"
	},
	// Shadows.
	boxShadow: {
		1: "0px 4px 3px #0000004a",
		2: "0px 2px 3px #0000001c"
	},
	textShadow: {
		1: "0px 2px 2px rgb(0 0 0 / 30%)"
	},
	// Spacings.
	space: [
		"0px", "4px", "8px", "16px", "24px", "32px", "48px", "64px"
	],
	fonts: [
		"'Roboto Slab', serif",
		"sans-serif"
	]
}

export default theme;