import { css, useTheme } from "@emotion/react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { connectInfiniteHits } from "react-instantsearch-dom";
import { Box, Flex } from "reflexbox";
import { ScheduleContext } from "../layouts/ViewerLayout";

export default connectInfiniteHits(function PdfSideBar ({hits,selectedTab}){
	const theme = useTheme();

    const facetStyles = theme => css`
	ul {
		list-style: none;
		padding-left: ${theme.space[3]};
		margin: 0px;
		li {
			color: ${theme.colors.brand2};
			a {
				color: inherit;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
			padding: ${theme.space[1]};
			position: relative;
			label {
				cursor: pointer;
				&:hover, &:hover > * {
					text-decoration: underline;
				}
				& > * {
					vertical-align: middle;
					display: inline-block;
				}
			}
			input {
				margin-right: ${theme.space[2]}
			}
			&:before {
				content: ">";
				font-family: monospace;
				font-weight: bold;
				font-size: 1.2em;
				position: absolute;
				left: -${theme.space[3]};
				top: 12px;
				transform: translate(0px, -50%);
			}
		}
	}
	span[class*=-count] {
		font-style: italic;
		font-size: 0.9rem;
		bottom: 1px;
		margin-left: ${theme.space[2]};
		&:before {
			content:"(";
		}
		&:after {
			content: ")";
		}
	}
	li[class*=--selected] {
		&, & > a {
			color: white;
		}
	}
`;

const { schedules } = useContext(ScheduleContext);
const [selectedShedule,setSelectedShedule] = useState("");

useEffect(()=>{
    {hits.map((hit, index) => {
        const schedule = schedules.find(schedule => Number(schedule.ref) === hit.schedule)
  setSelectedShedule(schedule);
})}
},[hits])
    return<Flex fontSize="1.2rem" bg="#140e41" color="#ffffff" p={[2]} flexDirection="column" height="100%" css={facetStyles(theme)} style={{display:selectedTab==0?"none":"block",position:"absolute",top:0,left:"0",right:0,zIndex:1}}>
    <Box p={2} pt={4} fontSize="1.6rem" fontFamily={theme.fonts[0]}>Files</Box>
    <Box  p={2}>{selectedShedule?.scheduleData?.data?.displayTitle}</Box>
    </Flex>
});