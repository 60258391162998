import { useTheme } from "@emotion/react";
import React, { forwardRef } from "react";
import { Flex as FlexBase, Box as BoxBase } from "reflexbox";

/**
 * @param {React.Component} Component The component to force the theme on.
 * 
 * @returns {FlexBase} Modified Flex component.
 */
function withTheme(Component) {
	return forwardRef((props, ref) => {
		const theme = useTheme();
		return <Component ref={ref} {...props} theme={theme} />
	});
}

export const Flex = withTheme(FlexBase);

export const Box = withTheme(BoxBase);
