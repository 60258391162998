export const SchedulesJson = [
    {
        "id": 41682,
        "year": 2021,
        "schedule": 231,
        "sectionCode": "HS05",
        "sectionDesc": "TEMPORARY WORKS",
        "level1Code": "",
        "level1Desc": "ERECT AND DISMANTLE SCAFFOLD",
        "level2Code": "HS0510",
        "level2Desc": "Independent tied scaffold to single storey dwelling. (per elevation)",
        "level3Code": "HS0510100",
        "level3Desc": "Independent tied scaffold (A4410/050 - 15.00 m2), Brick guards (A4410/080 - 5.00 nr), Roof edge double guardrail (A4410/087 - 5.00 m)",
        "alternativeCode": "[SCA-0050-A]",
        "measurement": "each",
        "material": 0,
        "plant": 0,
        "labour": 241.25,
        "total": 241.25,
        "hierarchy": {
            "lvl0": "231",
            "lvl1": "231 > HS05"
        },
        "objectID": "41682",
        "_highlightResult": {
            "level1Desc": {
                "value": "ERECT AND DISMANTLE SCAFFOLD",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level2Desc": {
                "value": "Independent tied scaffold to single storey dwelling. (per elevation)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Code": {
                "value": "HS0510100",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Desc": {
                "value": "Independent tied scaffold (A4410/050 - 15.00 m2), Brick guards (A4410/080 - 5.00 nr), Roof edge double guardrail (A4410/087 - 5.00 m)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "alternativeCode": {
                "value": "[SCA-0050-A]",
                "matchLevel": "none",
                "matchedWords": []
            }
        },
        "__position": 1
    },
    {
        "id": 41683,
        "year": 2021,
        "schedule": 231,
        "sectionCode": "HS05",
        "sectionDesc": "TEMPORARY WORKS",
        "level1Code": "",
        "level1Desc": "ERECT AND DISMANTLE SCAFFOLD",
        "level2Code": "HS0510",
        "level2Desc": "Independent tied scaffold to two storey dwelling. (per elevation)",
        "level3Code": "HS0510105",
        "level3Desc": "Independent tied scaffold (A4410/050 - 25.00 m2), Brick guards (A4410/080 - 5.00 nr), Roof edge double guardrail (A4410/087 - 5.00 m)",
        "alternativeCode": "[SCA-0105-A]",
        "measurement": "each",
        "material": 0,
        "plant": 0,
        "labour": 368.63,
        "total": 368.63,
        "hierarchy": {
            "lvl0": "231",
            "lvl1": "231 > HS05"
        },
        "objectID": "41683",
        "_highlightResult": {
            "level1Desc": {
                "value": "ERECT AND DISMANTLE SCAFFOLD",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level2Desc": {
                "value": "Independent tied scaffold to two storey dwelling. (per elevation)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Code": {
                "value": "HS0510105",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Desc": {
                "value": "Independent tied scaffold (A4410/050 - 25.00 m2), Brick guards (A4410/080 - 5.00 nr), Roof edge double guardrail (A4410/087 - 5.00 m)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "alternativeCode": {
                "value": "[SCA-0105-A]",
                "matchLevel": "none",
                "matchedWords": []
            }
        },
        "__position": 2
    },
    {
        "id": 41684,
        "year": 2021,
        "schedule": 231,
        "sectionCode": "HS05",
        "sectionDesc": "TEMPORARY WORKS",
        "level1Code": "",
        "level1Desc": "ERECT AND DISMANTLE SCAFFOLD",
        "level2Code": "HS0510",
        "level2Desc": "Independent tied scaffold to three storey dwelling. (per elevation)",
        "level3Code": "HS0510115",
        "level3Desc": "Independent tied scaffold (A4410/050 - 45.00 m2), Brick guards (A4410/080 - 5.00 nr), Roof edge double guardrail (A4410/087 - 5.00 m)",
        "alternativeCode": "[SCA-0115-A]",
        "measurement": "each",
        "material": 0,
        "plant": 0,
        "labour": 623.39,
        "total": 623.39,
        "hierarchy": {
            "lvl0": "231",
            "lvl1": "231 > HS05"
        },
        "objectID": "41684",
        "_highlightResult": {
            "level1Desc": {
                "value": "ERECT AND DISMANTLE SCAFFOLD",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level2Desc": {
                "value": "Independent tied scaffold to three storey dwelling. (per elevation)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Code": {
                "value": "HS0510115",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Desc": {
                "value": "Independent tied scaffold (A4410/050 - 45.00 m2), Brick guards (A4410/080 - 5.00 nr), Roof edge double guardrail (A4410/087 - 5.00 m)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "alternativeCode": {
                "value": "[SCA-0115-A]",
                "matchLevel": "none",
                "matchedWords": []
            }
        },
        "__position": 3
    },
    {
        "id": 41686,
        "year": 2021,
        "schedule": 231,
        "sectionCode": "HS05",
        "sectionDesc": "TEMPORARY WORKS",
        "level1Code": "",
        "level1Desc": "ERECT AND DISMANTLE SCAFFOLD",
        "level2Code": "HS0510",
        "level2Desc": "Independent tied scaffold to three storey dwelling. (per elevation)",
        "level3Code": "HS0510120",
        "level3Desc": "Independent tied scaffold (A4410/050 - 45.00 m2), Brick guards (A4410/080 - 5.00 nr), Roof edge double guardrail (A4410/087 - 5.00 m)",
        "alternativeCode": "[SCA-0115-A]",
        "measurement": "each",
        "material": 0,
        "plant": 0,
        "labour": 623.39,
        "total": 623.39,
        "hierarchy": {
            "lvl0": "231",
            "lvl1": "231 > HS05"
        },
        "objectID": "41686",
        "_highlightResult": {
            "level1Desc": {
                "value": "ERECT AND DISMANTLE SCAFFOLD",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level2Desc": {
                "value": "Independent tied scaffold to three storey dwelling. (per elevation)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Code": {
                "value": "HS0510120",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Desc": {
                "value": "Independent tied scaffold (A4410/050 - 45.00 m2), Brick guards (A4410/080 - 5.00 nr), Roof edge double guardrail (A4410/087 - 5.00 m)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "alternativeCode": {
                "value": "[SCA-0115-A]",
                "matchLevel": "none",
                "matchedWords": []
            }
        },
        "__position": 4
    },
    {
        "id": 40526,
        "year": 2021,
        "schedule": 231,
        "sectionCode": "HS10",
        "sectionDesc": "BRICKWORK AND BLOCKWORK",
        "level1Code": "",
        "level1Desc": "AIRBRICKS AND VENTS",
        "level2Code": "HS1005",
        "level2Desc": "Installing tumble drier vent kit",
        "level3Code": "HS1005100",
        "level3Desc": "Cutting to form openings through cavity wall of two 102 mm brick skins ne 0.50 m2 (C2010/600 - 1.00 nr), Supply and fit tumble drier vent kit inc flexible ducting (N1125/150 - 1.00 nr)",
        "alternativeCode": "[BRI-0100-C]",
        "measurement": "nr",
        "material": 9.24,
        "plant": 3.3,
        "labour": 54.19,
        "total": 66.73,
        "hierarchy": {
            "lvl0": "231",
            "lvl1": "231 > HS10"
        },
        "objectID": "40526",
        "_highlightResult": {
            "level1Desc": {
                "value": "AIRBRICKS AND VENTS",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level2Desc": {
                "value": "Installing tumble drier vent kit",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Code": {
                "value": "HS1005100",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Desc": {
                "value": "Cutting to form openings through cavity wall of two 102 mm brick skins ne 0.50 m2 (C2010/600 - 1.00 nr), Supply and fit tumble drier vent kit inc flexible ducting (N1125/150 - 1.00 nr)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "alternativeCode": {
                "value": "[BRI-0100-C]",
                "matchLevel": "none",
                "matchedWords": []
            }
        },
        "__position": 5
    },
    {
        "id": 40527,
        "year": 2021,
        "schedule": 231,
        "sectionCode": "HS10",
        "sectionDesc": "BRICKWORK AND BLOCKWORK",
        "level1Code": "",
        "level1Desc": "AIRBRICKS AND VENTS",
        "level2Code": "HS1005",
        "level2Desc": "Rebedding loose air brick",
        "level3Code": "HS1005105",
        "level3Desc": "Rebed loose air brick (C4005/115 - 1.00 nr)",
        "alternativeCode": "[BRI-0150-C]",
        "measurement": "nr",
        "material": 0.17,
        "plant": 0,
        "labour": 13.58,
        "total": 13.75,
        "hierarchy": {
            "lvl0": "231",
            "lvl1": "231 > HS10"
        },
        "objectID": "40527",
        "_highlightResult": {
            "level1Desc": {
                "value": "AIRBRICKS AND VENTS",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level2Desc": {
                "value": "Rebedding loose air brick",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Code": {
                "value": "HS1005105",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Desc": {
                "value": "Rebed loose air brick (C4005/115 - 1.00 nr)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "alternativeCode": {
                "value": "[BRI-0150-C]",
                "matchLevel": "none",
                "matchedWords": []
            }
        },
        "__position": 6
    },
    {
        "id": 40528,
        "year": 2021,
        "schedule": 231,
        "sectionCode": "HS10",
        "sectionDesc": "BRICKWORK AND BLOCKWORK",
        "level1Code": "",
        "level1Desc": "AIRBRICKS AND VENTS",
        "level2Code": "HS1005",
        "level2Desc": "Renewing existing airbrick in cavity wall",
        "level3Code": "HS1005110",
        "level3Desc": "Cutting out decayed, defective and cracked brickwork and renewing single brick with common brick in cement mortar (1:6), pointing (C4005/125 - 1.50 nr), Airbricks 225 x 225 mm in facework 102 mm thick (F3070/515 - 1.00 nr)",
        "alternativeCode": "[BRI-0200-C]",
        "measurement": "nr",
        "material": 27.45,
        "plant": 0,
        "labour": 24.64,
        "total": 52.09,
        "hierarchy": {
            "lvl0": "231",
            "lvl1": "231 > HS10"
        },
        "objectID": "40528",
        "_highlightResult": {
            "level1Desc": {
                "value": "AIRBRICKS AND VENTS",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level2Desc": {
                "value": "Renewing existing airbrick in cavity wall",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Code": {
                "value": "HS1005110",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Desc": {
                "value": "Cutting out decayed, defective and cracked brickwork and renewing single brick with common brick in cement mortar (1:6), pointing (C4005/125 - 1.50 nr), Airbricks 225 x 225 mm in facework 102 mm thick (F3070/515 - 1.00 nr)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "alternativeCode": {
                "value": "[BRI-0200-C]",
                "matchLevel": "none",
                "matchedWords": []
            }
        },
        "__position": 7
    },
    {
        "id": 40529,
        "year": 2021,
        "schedule": 231,
        "sectionCode": "HS10",
        "sectionDesc": "BRICKWORK AND BLOCKWORK",
        "level1Code": "",
        "level1Desc": "AIRBRICKS AND VENTS",
        "level2Code": "HS1005",
        "level2Desc": "Renewing existing vent with hit and miss vent",
        "level3Code": "HS1005115",
        "level3Desc": "Stripping off/removing/taking down louvred ventilators in preparation for replacement (C6045/500 - 1.00 nr), Hit and miss ventilators with flyscreen 225 x 150 mm, to brickwork (P2150/894 - 1.00 nr)",
        "alternativeCode": "[BRI-0250-C]",
        "measurement": "nr",
        "material": 2.03,
        "plant": 0,
        "labour": 13.93,
        "total": 15.96,
        "hierarchy": {
            "lvl0": "231",
            "lvl1": "231 > HS10"
        },
        "objectID": "40529",
        "_highlightResult": {
            "level1Desc": {
                "value": "AIRBRICKS AND VENTS",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level2Desc": {
                "value": "Renewing existing vent with hit and miss vent",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Code": {
                "value": "HS1005115",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Desc": {
                "value": "Stripping off/removing/taking down louvred ventilators in preparation for replacement (C6045/500 - 1.00 nr), Hit and miss ventilators with flyscreen 225 x 150 mm, to brickwork (P2150/894 - 1.00 nr)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "alternativeCode": {
                "value": "[BRI-0250-C]",
                "matchLevel": "none",
                "matchedWords": []
            }
        },
        "__position": 8
    },
    {
        "id": 40530,
        "year": 2021,
        "schedule": 231,
        "sectionCode": "HS10",
        "sectionDesc": "BRICKWORK AND BLOCKWORK",
        "level1Code": "",
        "level1Desc": "BLOCKWORK",
        "level2Code": "HS1008",
        "level2Desc": "Renewing existing common blockwork wall, 100 thick",
        "level3Code": "HS1008100",
        "level3Desc": "Block walls 100 mm thick (C1070/820 - 1.00 m2), walls 100mm thick (C4055/540 - 1.00 m2), Bonding to existing blockwork 100 mm thick (C4055/550 - 2.00 m)",
        "alternativeCode": "[BRI-0300-C]",
        "measurement": "m2",
        "material": 20.35,
        "plant": 2.8,
        "labour": 67.55,
        "total": 90.7,
        "hierarchy": {
            "lvl0": "231",
            "lvl1": "231 > HS10"
        },
        "objectID": "40530",
        "_highlightResult": {
            "level1Desc": {
                "value": "BLOCKWORK",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level2Desc": {
                "value": "Renewing existing common blockwork wall, 100 thick",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Code": {
                "value": "HS1008100",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Desc": {
                "value": "Block walls 100 mm thick (C1070/820 - 1.00 m2), walls 100mm thick (C4055/540 - 1.00 m2), Bonding to existing blockwork 100 mm thick (C4055/550 - 2.00 m)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "alternativeCode": {
                "value": "[BRI-0300-C]",
                "matchLevel": "none",
                "matchedWords": []
            }
        },
        "__position": 9
    },
    {
        "id": 40531,
        "year": 2021,
        "schedule": 231,
        "sectionCode": "HS10",
        "sectionDesc": "BRICKWORK AND BLOCKWORK",
        "level1Code": "",
        "level1Desc": "BLOCKWORK",
        "level2Code": "HS1008",
        "level2Desc": "Renewing existing single common blocks",
        "level3Code": "HS1008105",
        "level3Desc": "Block walls 100 mm thick (C1070/820 - 0.10 m2), walls 100mm thick (C4055/540 - 0.10 m2)",
        "alternativeCode": "[BRI-0310-C]",
        "measurement": "nr",
        "material": 1.65,
        "plant": 0.28,
        "labour": 3.77,
        "total": 5.71,
        "hierarchy": {
            "lvl0": "231",
            "lvl1": "231 > HS10"
        },
        "objectID": "40531",
        "_highlightResult": {
            "level1Desc": {
                "value": "BLOCKWORK",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level2Desc": {
                "value": "Renewing existing single common blocks",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Code": {
                "value": "HS1008105",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Desc": {
                "value": "Block walls 100 mm thick (C1070/820 - 0.10 m2), walls 100mm thick (C4055/540 - 0.10 m2)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "alternativeCode": {
                "value": "[BRI-0310-C]",
                "matchLevel": "none",
                "matchedWords": []
            }
        },
        "__position": 10
    },
    {
        "id": 40532,
        "year": 2021,
        "schedule": 231,
        "sectionCode": "HS10",
        "sectionDesc": "BRICKWORK AND BLOCKWORK",
        "level1Code": "",
        "level1Desc": "BUILDERSWORK IN CONNECTION WITH SERVICES",
        "level2Code": "HS1010",
        "level2Desc": "Making good hole in brickwork after removal of pipe or the like",
        "level3Code": "HS1010100",
        "level3Desc": "Filling in openings 450 x 600 x 102 mm thick, bonding and wedging and pinning to existing (C2065/800 - 0.50 nr)",
        "alternativeCode": "[BRI-0350-C]",
        "measurement": "nr",
        "material": 3.27,
        "plant": 0,
        "labour": 18.68,
        "total": 21.95,
        "hierarchy": {
            "lvl0": "231",
            "lvl1": "231 > HS10"
        },
        "objectID": "40532",
        "_highlightResult": {
            "level1Desc": {
                "value": "BUILDERSWORK IN CONNECTION WITH SERVICES",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level2Desc": {
                "value": "Making good hole in brickwork after removal of pipe or the like",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Code": {
                "value": "HS1010100",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Desc": {
                "value": "Filling in openings 450 x 600 x 102 mm thick, bonding and wedging and pinning to existing (C2065/800 - 0.50 nr)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "alternativeCode": {
                "value": "[BRI-0350-C]",
                "matchLevel": "none",
                "matchedWords": []
            }
        },
        "__position": 11
    },
    {
        "id": 40533,
        "year": 2021,
        "schedule": 231,
        "sectionCode": "HS10",
        "sectionDesc": "BRICKWORK AND BLOCKWORK",
        "level1Code": "",
        "level1Desc": "CAVITY TRAYS",
        "level2Code": "HS1012",
        "level2Desc": "Installing new cavity tray damp proof course",
        "level3Code": "HS1012100",
        "level3Desc": "Cavity trays ne 225 mm wide, horizontal (F3015/170 - 1.00 m2)",
        "alternativeCode": "[BRI-0400-C]",
        "measurement": "m",
        "material": 10.44,
        "plant": 0.28,
        "labour": 4.26,
        "total": 14.98,
        "hierarchy": {
            "lvl0": "231",
            "lvl1": "231 > HS10"
        },
        "objectID": "40533",
        "_highlightResult": {
            "level1Desc": {
                "value": "CAVITY TRAYS",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level2Desc": {
                "value": "Installing new cavity tray damp proof course",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Code": {
                "value": "HS1012100",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Desc": {
                "value": "Cavity trays ne 225 mm wide, horizontal (F3015/170 - 1.00 m2)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "alternativeCode": {
                "value": "[BRI-0400-C]",
                "matchLevel": "none",
                "matchedWords": []
            }
        },
        "__position": 12
    },
    {
        "id": 40534,
        "year": 2021,
        "schedule": 231,
        "sectionCode": "HS10",
        "sectionDesc": "BRICKWORK AND BLOCKWORK",
        "level1Code": "",
        "level1Desc": "CHIMNEY BREASTS",
        "level2Code": "HS1016",
        "level2Desc": "Renewing existing common brick chimney breast, 1 storey high, n/e 1.20m wide",
        "level3Code": "HS1016100",
        "level3Desc": "Acrow props (C1080/970 - 2.00 nr), Acrow props (C1090/970 - 2.00 nr), Removing fire grating (C2010/132 - 1.00 nr), Removing fire surround and hearth, cleaning off, setting aside for re-use (C2010/136 - 1.00 nr), Removing fireback (C2010/138 - 1.00 nr), Removing skirtings (C2010/280 - 1.80 m), Cutting back brickwork projections by 328 mm to 1116 mm wide chimney breast (C2010/741 - 3.00 m), Firebacks 450 mm, bedding in fire cement mortar (C4080/765 - 1.00 nr), Fire surround and hearth (C4090/840 - 1.00 nr), Walls 327 mm thick (F1010/014 - 3.60 m2), Lintels 0.0125 to 0.0250 m2 sectional area keyed on exposed faces (F3110/065 - 1.00 m), Walls over 300 mm wide to brickwork, blockwork or stonework 1 to 5 m2 (M2025/051 - 5.00 m2), Angle beads (M2080/900 - 6.00 m), Skirtings, picture rails, architraves and the like 19 x 69 mm (fin) (P2010/066 - 1.80 m), Plugging at 300 mm centres (P2075/665 - 1.80 m), Screwing at 300 mm centres (P2075/670 - 1.80 m)",
        "alternativeCode": "[BRI-0450-C]",
        "measurement": "nr",
        "material": 429.92,
        "plant": 64.97,
        "labour": 801.51,
        "total": 1296.4,
        "hierarchy": {
            "lvl0": "231",
            "lvl1": "231 > HS10"
        },
        "objectID": "40534",
        "_highlightResult": {
            "level1Desc": {
                "value": "CHIMNEY BREASTS",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level2Desc": {
                "value": "Renewing existing common brick chimney breast, 1 storey high, n/e 1.20m wide",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Code": {
                "value": "HS1016100",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Desc": {
                "value": "Acrow props (C1080/970 - 2.00 nr), Acrow props (C1090/970 - 2.00 nr), Removing fire grating (C2010/132 - 1.00 nr), Removing fire surround and hearth, cleaning off, setting aside for re-use (C2010/136 - 1.00 nr), Removing fireback (C2010/138 - 1.00 nr), Removing skirtings (C2010/280 - 1.80 m), Cutting back brickwork projections by 328 mm to 1116 mm wide chimney breast (C2010/741 - 3.00 m), Firebacks 450 mm, bedding in fire cement mortar (C4080/765 - 1.00 nr), Fire surround and hearth (C4090/840 - 1.00 nr), Walls 327 mm thick (F1010/014 - 3.60 m2), Lintels 0.0125 to 0.0250 m2 sectional area keyed on exposed faces (F3110/065 - 1.00 m), Walls over 300 mm wide to brickwork, blockwork or stonework 1 to 5 m2 (M2025/051 - 5.00 m2), Angle beads (M2080/900 - 6.00 m), Skirtings, picture rails, architraves and the like 19 x 69 mm (fin) (P2010/066 - 1.80 m), Plugging at 300 mm centres (P2075/665 - 1.80 m), Screwing at 300 mm centres (P2075/670 - 1.80 m)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "alternativeCode": {
                "value": "[BRI-0450-C]",
                "matchLevel": "none",
                "matchedWords": []
            }
        },
        "__position": 13
    },
    {
        "id": 40535,
        "year": 2021,
        "schedule": 231,
        "sectionCode": "HS10",
        "sectionDesc": "BRICKWORK AND BLOCKWORK",
        "level1Code": "",
        "level1Desc": "CHIMNEY BREASTS",
        "level2Code": "HS1016",
        "level2Desc": "Renewing existing facing brick chimney breast, 1 storey high, n/e 1.20m wide",
        "level3Code": "HS1016105",
        "level3Desc": "Acrow props (C1080/970 - 2.00 nr), Acrow props (C1090/970 - 2.00 nr), Removing fire grating (C2010/132 - 1.00 nr), Removing fire surround and hearth, cleaning off, setting aside for re-use (C2010/136 - 1.00 nr), Removing fireback (C2010/138 - 1.00 nr), Removing skirtings (C2010/280 - 1.80 m), Cutting back brickwork projections by 328 mm to 1116 mm wide chimney breast (C2010/741 - 3.00 m), Firebacks 450 mm, bedding in fire cement mortar (C4080/765 - 1.00 nr), Fire surround and hearth (C4090/840 - 1.00 nr), Walls 327 mm thick, facework one side (F1040/108 - 3.60 m2), Lintels 0.0125 to 0.0250 m2 sectional area keyed on exposed faces (F3110/065 - 1.00 m), Skirtings, picture rails, architraves and the like 19 x 69 mm (fin) (P2010/066 - 1.80 m), Plugging at 300 mm centres (P2075/665 - 1.80 m), Screwing at 300 mm centres (P2075/670 - 1.80 m)",
        "alternativeCode": "[BRI-0500-C]",
        "measurement": "nr",
        "material": 409.8,
        "plant": 65.51,
        "labour": 793.94,
        "total": 1269.24,
        "hierarchy": {
            "lvl0": "231",
            "lvl1": "231 > HS10"
        },
        "objectID": "40535",
        "_highlightResult": {
            "level1Desc": {
                "value": "CHIMNEY BREASTS",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level2Desc": {
                "value": "Renewing existing facing brick chimney breast, 1 storey high, n/e 1.20m wide",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Code": {
                "value": "HS1016105",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Desc": {
                "value": "Acrow props (C1080/970 - 2.00 nr), Acrow props (C1090/970 - 2.00 nr), Removing fire grating (C2010/132 - 1.00 nr), Removing fire surround and hearth, cleaning off, setting aside for re-use (C2010/136 - 1.00 nr), Removing fireback (C2010/138 - 1.00 nr), Removing skirtings (C2010/280 - 1.80 m), Cutting back brickwork projections by 328 mm to 1116 mm wide chimney breast (C2010/741 - 3.00 m), Firebacks 450 mm, bedding in fire cement mortar (C4080/765 - 1.00 nr), Fire surround and hearth (C4090/840 - 1.00 nr), Walls 327 mm thick, facework one side (F1040/108 - 3.60 m2), Lintels 0.0125 to 0.0250 m2 sectional area keyed on exposed faces (F3110/065 - 1.00 m), Skirtings, picture rails, architraves and the like 19 x 69 mm (fin) (P2010/066 - 1.80 m), Plugging at 300 mm centres (P2075/665 - 1.80 m), Screwing at 300 mm centres (P2075/670 - 1.80 m)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "alternativeCode": {
                "value": "[BRI-0500-C]",
                "matchLevel": "none",
                "matchedWords": []
            }
        },
        "__position": 14
    },
    {
        "id": 40536,
        "year": 2021,
        "schedule": 231,
        "sectionCode": "HS10",
        "sectionDesc": "BRICKWORK AND BLOCKWORK",
        "level1Code": "",
        "level1Desc": "CHIMNEY COPES",
        "level2Code": "HS1018",
        "level2Desc": "Renewing existing precast concrete chimney cope, small (n/e 0.25m² plan area)",
        "level3Code": "HS1018110",
        "level3Desc": "5.00 - 6.00 m From ground level (A4415/115 - 1.00 nr), 5.00 - 6.00 m From ground level (A4470/115 - 1.00 nr), Removing stone or precast concrete copings or sills 0.025 to 0.050 m2 sectional area, setting aside for re-use, making good structures (C2010/052 - 0.50 m), Capping 440 x 440 x 50 mm (C4095/875 - 1.00 nr), On surfaces ne 225 mm wide, horizontal (F3020/150 - 0.20 m2)",
        "alternativeCode": "[BRI-0550-C]",
        "measurement": "nr",
        "material": 33.89,
        "plant": 190.88,
        "labour": 124.05,
        "total": 348.83,
        "hierarchy": {
            "lvl0": "231",
            "lvl1": "231 > HS10"
        },
        "objectID": "40536",
        "_highlightResult": {
            "level1Desc": {
                "value": "CHIMNEY COPES",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level2Desc": {
                "value": "Renewing existing precast concrete chimney cope, small (n/e 0.25m² plan area)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Code": {
                "value": "HS1018110",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Desc": {
                "value": "5.00 - 6.00 m From ground level (A4415/115 - 1.00 nr), 5.00 - 6.00 m From ground level (A4470/115 - 1.00 nr), Removing stone or precast concrete copings or sills 0.025 to 0.050 m2 sectional area, setting aside for re-use, making good structures (C2010/052 - 0.50 m), Capping 440 x 440 x 50 mm (C4095/875 - 1.00 nr), On surfaces ne 225 mm wide, horizontal (F3020/150 - 0.20 m2)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "alternativeCode": {
                "value": "[BRI-0550-C]",
                "matchLevel": "none",
                "matchedWords": []
            }
        },
        "__position": 15
    },
    {
        "id": 40537,
        "year": 2021,
        "schedule": 231,
        "sectionCode": "HS10",
        "sectionDesc": "BRICKWORK AND BLOCKWORK",
        "level1Code": "",
        "level1Desc": "CHIMNEY COPES",
        "level2Code": "HS1018",
        "level2Desc": "Renewing existing precast concrete chimney cope, medium (0.25-0.50m² plan area)",
        "level3Code": "HS1018115",
        "level3Desc": "5.00 - 6.00 m From ground level (A4415/115 - 1.00 nr), 5.00 - 6.00 m From ground level (A4470/115 - 1.00 nr), Removing stone or precast concrete copings or sills 0.025 to 0.050 m2 sectional area, setting aside for re-use, making good structures (C2010/052 - 1.00 m), Capping 440 x 1116 x 50 mm (C4095/885 - 1.00 nr), On surfaces ne 225 mm wide, horizontal (F3020/150 - 0.45 m2)",
        "alternativeCode": "[BRI-0600-C]",
        "measurement": "nr",
        "material": 66.21,
        "plant": 190.95,
        "labour": 131.2,
        "total": 388.37,
        "hierarchy": {
            "lvl0": "231",
            "lvl1": "231 > HS10"
        },
        "objectID": "40537",
        "_highlightResult": {
            "level1Desc": {
                "value": "CHIMNEY COPES",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level2Desc": {
                "value": "Renewing existing precast concrete chimney cope, medium (0.25-0.50m² plan area)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Code": {
                "value": "HS1018115",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Desc": {
                "value": "5.00 - 6.00 m From ground level (A4415/115 - 1.00 nr), 5.00 - 6.00 m From ground level (A4470/115 - 1.00 nr), Removing stone or precast concrete copings or sills 0.025 to 0.050 m2 sectional area, setting aside for re-use, making good structures (C2010/052 - 1.00 m), Capping 440 x 1116 x 50 mm (C4095/885 - 1.00 nr), On surfaces ne 225 mm wide, horizontal (F3020/150 - 0.45 m2)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "alternativeCode": {
                "value": "[BRI-0600-C]",
                "matchLevel": "none",
                "matchedWords": []
            }
        },
        "__position": 16
    },
    {
        "id": 40538,
        "year": 2021,
        "schedule": 231,
        "sectionCode": "HS10",
        "sectionDesc": "BRICKWORK AND BLOCKWORK",
        "level1Code": "",
        "level1Desc": "CHIMNEY COPES",
        "level2Code": "HS1018",
        "level2Desc": "Renewing existing precast concrete chimney cope, large (0.50-0.75m² plan area)",
        "level3Code": "HS1018120",
        "level3Desc": "5.00 - 6.00 m From ground level (A4420/115 - 1.00 nr), 5.00 - 6.00 m From ground level (A4475/115 - 1.00 nr), Removing stone or precast concrete copings or sills 0.025 to 0.050 m2 sectional area, setting aside for re-use, making good structures (C2010/052 - 1.00 m), Capping 440 x 778 x 50 mm (C4095/880 - 1.00 nr), On surfaces ne 225 mm wide, horizontal (F3020/150 - 0.70 m2)",
        "alternativeCode": "[BRI-0650-C]",
        "measurement": "nr",
        "material": 57.32,
        "plant": 257.6,
        "labour": 187.24,
        "total": 502.15,
        "hierarchy": {
            "lvl0": "231",
            "lvl1": "231 > HS10"
        },
        "objectID": "40538",
        "_highlightResult": {
            "level1Desc": {
                "value": "CHIMNEY COPES",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level2Desc": {
                "value": "Renewing existing precast concrete chimney cope, large (0.50-0.75m² plan area)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Code": {
                "value": "HS1018120",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Desc": {
                "value": "5.00 - 6.00 m From ground level (A4420/115 - 1.00 nr), 5.00 - 6.00 m From ground level (A4475/115 - 1.00 nr), Removing stone or precast concrete copings or sills 0.025 to 0.050 m2 sectional area, setting aside for re-use, making good structures (C2010/052 - 1.00 m), Capping 440 x 778 x 50 mm (C4095/880 - 1.00 nr), On surfaces ne 225 mm wide, horizontal (F3020/150 - 0.70 m2)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "alternativeCode": {
                "value": "[BRI-0650-C]",
                "matchLevel": "none",
                "matchedWords": []
            }
        },
        "__position": 17
    },
    {
        "id": 40539,
        "year": 2021,
        "schedule": 231,
        "sectionCode": "HS10",
        "sectionDesc": "BRICKWORK AND BLOCKWORK",
        "level1Code": "",
        "level1Desc": "CHIMNEY COPES",
        "level2Code": "HS1018",
        "level2Desc": "Renewing existing precast concrete chimney cope, extra large (0.75-1.00m² plan area)",
        "level3Code": "HS1018125",
        "level3Desc": "5.00 - 6.00 m From ground level (A4420/115 - 1.00 nr), 5.00 - 6.00 m From ground level (A4475/115 - 1.00 nr), Removing stone or precast concrete copings or sills 0.025 to 0.050 m2 sectional area, setting aside for re-use, making good structures (C2010/052 - 1.50 m), Capping 440 x 778 x 50 mm (C4095/880 - 1.50 nr), On surfaces ne 225 mm wide, horizontal (F3020/150 - 0.95 m2)",
        "alternativeCode": "[BRI-0700-C]",
        "measurement": "nr",
        "material": 83.56,
        "plant": 257.67,
        "labour": 193.58,
        "total": 534.81,
        "hierarchy": {
            "lvl0": "231",
            "lvl1": "231 > HS10"
        },
        "objectID": "40539",
        "_highlightResult": {
            "level1Desc": {
                "value": "CHIMNEY COPES",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level2Desc": {
                "value": "Renewing existing precast concrete chimney cope, extra large (0.75-1.00m² plan area)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Code": {
                "value": "HS1018125",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Desc": {
                "value": "5.00 - 6.00 m From ground level (A4420/115 - 1.00 nr), 5.00 - 6.00 m From ground level (A4475/115 - 1.00 nr), Removing stone or precast concrete copings or sills 0.025 to 0.050 m2 sectional area, setting aside for re-use, making good structures (C2010/052 - 1.50 m), Capping 440 x 778 x 50 mm (C4095/880 - 1.50 nr), On surfaces ne 225 mm wide, horizontal (F3020/150 - 0.95 m2)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "alternativeCode": {
                "value": "[BRI-0700-C]",
                "matchLevel": "none",
                "matchedWords": []
            }
        },
        "__position": 18
    },
    {
        "id": 40540,
        "year": 2021,
        "schedule": 231,
        "sectionCode": "HS10",
        "sectionDesc": "BRICKWORK AND BLOCKWORK",
        "level1Code": "",
        "level1Desc": "CHIMNEY POTS AND FLUE TERMINALS",
        "level2Code": "HS1020",
        "level2Desc": "Installing terra cotta vented cap to chimney pot",
        "level3Code": "HS1020130",
        "level3Desc": "Terra cotta vented cap to chimney pot (C4080/754 - 1.00 nr)",
        "alternativeCode": "[BRI-0750-C]",
        "measurement": "nr",
        "material": 32.53,
        "plant": 0,
        "labour": 3.86,
        "total": 36.39,
        "hierarchy": {
            "lvl0": "231",
            "lvl1": "231 > HS10"
        },
        "objectID": "40540",
        "_highlightResult": {
            "level1Desc": {
                "value": "CHIMNEY POTS AND FLUE TERMINALS",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level2Desc": {
                "value": "Installing terra cotta vented cap to chimney pot",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Code": {
                "value": "HS1020130",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Desc": {
                "value": "Terra cotta vented cap to chimney pot (C4080/754 - 1.00 nr)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "alternativeCode": {
                "value": "[BRI-0750-C]",
                "matchLevel": "none",
                "matchedWords": []
            }
        },
        "__position": 19
    },
    {
        "id": 40541,
        "year": 2021,
        "schedule": 231,
        "sectionCode": "HS10",
        "sectionDesc": "BRICKWORK AND BLOCKWORK",
        "level1Code": "",
        "level1Desc": "CHIMNEY POTS AND FLUE TERMINALS",
        "level2Code": "HS1020",
        "level2Desc": "Rebedding existing chimney pot",
        "level3Code": "HS1020135",
        "level3Desc": "Removing chimney pot and flaunching from stack, setting aside pot for re-use (C2010/111 - 1.00 nr), Chimney pot, setting and flaunching in cement mortar (1:3) (C4078/740 - 1.00 nr)",
        "alternativeCode": "[BRI-0800-C]",
        "measurement": "nr",
        "material": 3.06,
        "plant": 0,
        "labour": 46.61,
        "total": 49.67,
        "hierarchy": {
            "lvl0": "231",
            "lvl1": "231 > HS10"
        },
        "objectID": "40541",
        "_highlightResult": {
            "level1Desc": {
                "value": "CHIMNEY POTS AND FLUE TERMINALS",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level2Desc": {
                "value": "Rebedding existing chimney pot",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Code": {
                "value": "HS1020135",
                "matchLevel": "none",
                "matchedWords": []
            },
            "level3Desc": {
                "value": "Removing chimney pot and flaunching from stack, setting aside pot for re-use (C2010/111 - 1.00 nr), Chimney pot, setting and flaunching in cement mortar (1:3) (C4078/740 - 1.00 nr)",
                "matchLevel": "none",
                "matchedWords": []
            },
            "alternativeCode": {
                "value": "[BRI-0800-C]",
                "matchLevel": "none",
                "matchedWords": []
            }
        },
        "__position": 20
    },
    

]
