/** @jsxImportSource @emotion/react */
import axios from "axios";
import { useContext } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { Button, ImgIcon } from "..";
import { API_ROOT } from "../../constants";
import { UserContext } from "../../context";
import { Box, Flex } from "../FlexBox";
import _ from "lodash";
import { css } from "@emotion/react";
import { useTheme } from "@emotion/react";

import { Uploader } from "uploader"; // Installed by "react-uploader".
import { UploadButton } from "react-uploader";
import { uploadFile } from "../../services/api";
import { useIsAdmin } from "../../helpers";
import withViewerLayout from "../layouts/ViewerLayout";
import OpenPdf from "./openPdf";
import { Link } from "react-router-dom";

const uploader = Uploader({
	apiKey: "free" // Get production API keys from Upload.io
});

const options = { multi: true };

async function getProducts(token) {
	try {
		// const response = await axios.get(`${API_ROOT}/viewer/products`, {
		// 	params: {
		// 		type: ["eBook", "sfg20", "smv"].join(","),
		// 		showHidden: "true"
		// 	},
		// 	headers: {
		// 		Authorization: `Bearer ${token}`
		// 	}
		// });
		const response = await axios.get(`${API_ROOT}/active-products`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
		return response.data.products;
	} catch (error) {
		console.error("Couldn't get products:", error);
		throw new Error(error.message);
	}
}

 function ProductLi() {

	const [products, setProducts] = useState([]);
	const [loading, setLoading] = useState(false);
	const { token } = useContext(UserContext);

	useEffect(() => {
		setLoading(true);
		if (token) (async () => {
			try {
				const products = await getProducts(token);
				setProducts(products);
			} catch (error) {
				console.error("Couldn't get products:", error);
			} finally {
				setLoading(false);
			}
		})();
	}, [token]);

	const scheduleTypes = useMemo(() => {
		const filtered = products
			.filter(product => product.scheduleType)
			.sort((a, b) => b.year - a.year)
		return _.groupBy(filtered, product => product.scheduleType);
	}, [products]);

	return <>
		{loading && <Box width="100%" p={3}>Loading..</Box>}
		{Object.entries(scheduleTypes).map(([title, items]) =>
			<Schedule title={title} items={items} key={title} />
		)}
	</>

}

function Schedule({ title, items }) {

	const [open, setOpen] = useState(false);
	const theme = useTheme();

	return <Flex flexDirection="column" style={{margin:"0px 100px"}}>
		
		<Flex flexWrap="wrap" onClick={e => setOpen(!open)} css={css`
			cursor: pointer;
			&:hover {
				background-color: ${theme.colors.brand2};
			}
		`}>
			<Box flex="auto" fontSize="2.4rem" fontFamily={0} fontWeight="bold" p={3} pb={1}>
				{title}
			</Box>
			<Box fontFamily="monospace" fontSize="2.4rem" p={3} css={open && css`
				transform: rotate(180deg);
			`}>
				&#9660;
			</Box>
		</Flex>

		<Box fontSize="1.6rem" fontFamily={0} p={3} pt={0}>
			{items.length} items
		</Box>
		{open && <Flex flexWrap="wrap" p={2} pt={0}>
			{items.map(product => {
				return <Box p={2} width={["100%", "100%", "100%", "50%"]} key={product.ref}>
					<ScheduleItem product={product} />
				</Box>
			})}
		</Flex>}
	</Flex>
}

function ScheduleItem({ product }) {

	const { token } = useContext(UserContext);
	const { user } = useContext(UserContext);
	const isAdmin = useIsAdmin();
    const [data,setData]= useState([]);
	const [open,setOpen]= useState(false);


	return(
        <Flex key={product.key} bg="#ffffff" alignItems="center" justifyContent="space-between" style={{margin:'0px 40px'}} margin="0px 80px">
		<Box fontSize="0px">
			<ImgIcon
				src={product?.data?.img}
				size={40}
			/>
		</Box>
		<Box p={2} fontFamily={0}>
			{product.scheduleType}
		</Box>
		<Box p={2} fontFamily={0} fontWeight="bold">
			{product.version}
		</Box>
		<Box p={2} fontFamily={0}>{product.year}</Box>
       <Button onClick={(e)=> setOpen(true)} style={{width:'70px'}}>PDF</Button>
	   {
		open && <OpenPdf pdf={`${API_ROOT}/images/${product.scheduleData.id}.pdf`} />
	   }
	
	</Flex>
    )
}


export default withViewerLayout(ProductLi)