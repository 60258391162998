/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useContext, useState } from "react";
import { ImgIcon } from "..";
import { UserContext } from "../../context";
import { useIsAdmin } from "../../helpers";
import { Box, Flex } from "../FlexBox";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { API_ROOT, HOME, WP_ROOT } from "../../constants";
import { useLocation } from "react-router-dom";

const logo = "/logo.png";

const ColorBar = styled.div`
  background: linear-gradient(to right, #0074b5 0%, #75daff 100%);
  height: 6px;
`;

const AccountButton = styled.button`
  border: none;
  padding: ${(props) => props.theme.space[2]} ${(props) => props.theme.space[3]};
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.brand3};
  color: #ffffff;
  font-family: "Roboto Slab";
  font-weight: 700;
`;

export default function AccountLayout({ children, withAdminLink = false }) {
  const { user, logout } = useContext(UserContext);
  const isAdmin = useIsAdmin();
  const theme = useTheme();
  let location = useLocation();

  // initialize cookies
  const cookies = new Cookies();


  function deleteCookie(cookieName) {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
  return (
    <>
    {/* Colour bar */}
    <ColorBar />
    {/* Header */}
    <Flex
      width="100%"
      backgroundColor="#ffffff"
      px={6}
      alignItems="center"
      justifyContent="space-between"
    >
      <Box>
        <a href={HOME}>
          <ImgIcon cursor="inherit" src={logo} size={85} />
        </a>
      </Box>
      {Boolean(user) && (
        <Flex
          fontSize="1.1rem"
          flexWrap="wrap"
          textAlign="right"
          justifyContent="end"
        >
          <Box pb={2} width="100%">
            Logged in as{" "}
            <b
              css={css`
                color: ${theme.colors.brand2};
              `}
            >
              {user.n}
            </b>
          </Box>
          {withAdminLink && isAdmin && (
            <Box fontWeight="bold" pr={2}>
              <Link to="/admin">
                <AccountButton>Go to Admin</AccountButton>
              </Link>
            </Box>
          )}
          
          {/* {location.pathname === "/subscriber/products" &&  (
            <Box fontWeight="bold" pr={2}>
              <Link to="/viewer">
                <AccountButton>Go to Viewer</AccountButton>
              </Link>
            </Box>
          )} */}

          {/* { location.pathname !== "/subscriber/products" && (
            <Box fontWeight="bold" pr={2}>
              <Link to="/subscriber/products">
                <AccountButton>View Guidelines Notes</AccountButton>
              </Link>
            </Box>
          )} */}

          { location.pathname == "/admin" && (
            <Box fontWeight="bold" pr={2}>
              <Link to="/viewer">
                <AccountButton>Go to Viewer</AccountButton>
              </Link>
            </Box>
          )}

          <Box fontWeight="bold">
         
              <AccountButton
              onClick={()=>{logout()}}
              >
                Log out
              </AccountButton>      
          </Box>

        </Flex>
      )}
    </Flex>
    {children}
  </>
  );
}
