import React from 'react'
import { Box, Flex } from 'reflexbox'

const Tab = ({selectedTab,updateTab}) => {
    const Tab = [
        {title:"Schedule Viewer"},
        {title:"Browse schedule"}
    ]

    const updateTabIndex = (index)=>{
        updateTab(index)
    }

  return (
    <Flex>
        {Tab.map((item,index)=>{
           return <Box key={index} fontWeight={400} fontSize="1.2rem" onClick={()=>{updateTabIndex(index)}} color={selectedTab == index?"":"#41bcea"} bg={selectedTab == index ?"#f2f2f2":""} padding={10} marginLeft={10} style={{cursor:"pointer"}}>{item.title}</Box>
        })}
    </Flex>
  )
}

export default Tab
