/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTheme } from "@emotion/react"
import { useContext } from "react";
import { HierarchicalMenu, RefinementList } from "react-instantsearch-dom";
import { Box, Flex } from "../FlexBox"
import { ScheduleContext } from "../layouts/ViewerLayout";

const facetStyles = theme => css`
	ul {
		list-style: none;
		padding-left: ${theme.space[3]};
		margin: 0px;
		li {
			color: ${theme.colors.brand2};
			a {
				color: inherit;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
			padding: ${theme.space[1]};
			position: relative;
			label {
				cursor: pointer;
				&:hover, &:hover > * {
					text-decoration: underline;
				}
				& > * {
					vertical-align: middle;
					display: inline-block;
				}
			}
			input {
				margin-right: ${theme.space[2]}
			}
			&:before {
				content: ">";
				font-family: monospace;
				font-weight: bold;
				font-size: 1.2em;
				position: absolute;
				left: -${theme.space[3]};
				top: 12px;
				transform: translate(0px, -50%);
			}
		}
	}
	span[class*=-count] {
		font-style: italic;
		font-size: 0.9rem;
		bottom: 1px;
		margin-left: ${theme.space[2]};
		&:before {
			content:"(";
		}
		&:after {
			content: ")";
		}
	}
	li[class*=--selected] {
		&, & > a {
			color: white;
		}
	}
`;

function YearSelector() {
	const theme = useTheme();
	return <>
		<Box p={2} fontSize="1.6rem" color="#ffffff" fontFamily={theme.fonts[0]}>
			Year
		</Box>
		<Box
			color="brand2"
			fontWeight="normal"
			css={[facetStyles(theme), css`
				ul {
					padding-left: 0px;
					li:before {
						display: none;
					}
				}
			`]}
		>
			<RefinementList
				attribute="year"
			/>
		</Box>
	</>
}

const scheduleAttributes = [
	"hierarchy.lvl0",
	"hierarchy.lvl1",
	"hierarchy.lvl2",
];

export default function ScheduleFilters(props) {

	const theme = useTheme();
	const { schedules } = useContext(ScheduleContext);

	return <Flex fontSize="1.2rem" bg="brand3" color="#ffffff" p={[2]} flexDirection="column" height="100%" css={facetStyles(theme)}>
		{/* Header */}
		<Box p={2} pt={4} fontSize="1.6rem" fontFamily={theme.fonts[0]}>
			Schedule / Section
		</Box>
		{/* Pick schedule/section */}
		<Box p={2}>
			<HierarchicalMenu
				limit={50}
				attributes={scheduleAttributes}
				showParentLevel
				transformItems={items => items.map(item => {
					// Find a matching schedule.
					const match = schedules.find(schedule => schedule.ref === item.label);
					// If we have one, name it based on schedule name.
					if (match) {
						const label = [];
						if (match.version && match.version !== "standard") label.push(match.version.toUpperCase());
						label.push(match.scheduleType, match.year);
						item.label = label.join(" ");
					}
					// All done.
					return item;
				})}
			/>
		</Box>
		{/* Pick years */}
		<YearSelector />
	</Flex>
}
