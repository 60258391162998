/** @jsxImportSource @emotion/react */
import SearchBar from "../components/viewer/SearchBar";
import { Box, Flex } from "../components/FlexBox";
import withViewerLayout from "../components/layouts/ViewerLayout";
import ScheduleDataHits from "../components/viewer/ScheduleDataHits";
import ScheduleFilters from "../components/viewer/ScheduleFilters";
import { useState } from "react";
import '../index.css'
import Tab from "../components/tab";
import PdfViewer from "../components/pdf-viewer/PdfViewer";
import PdfSideBar from "../components/pdf-sidebar/PdfSideBar";

function Viewer() {

	const [showPdf, setShowPdf] = useState(false)

	const showPdfFile = () => {
		setShowPdf(!showPdf)

	}
    
	const[selectedTab,setSelectedTab] = useState(0);

	return <Box>
		<Flex alignItems="stretch">
		<Box width="20%" minWidth="310px">
		</Box>
		<Box>
			<Tab selectedTab={selectedTab} updateTab={setSelectedTab}/>
		</Box>
		</Flex>
		<Flex alignItems="stretch">
		<Box width="20%" minWidth="310px" style={{position:"relative"}}>
			<ScheduleFilters />
			<PdfSideBar selectedTab={selectedTab}/>
		</Box>
		{selectedTab ==0 ?<Flex bg="#ffffff" width="80%" flexDirection="column" p={[2, 3]}>

			<Box p={2}>
				<SearchBar />
			</Box>

			{/* <div className="show-pdf">
				<button onClick={showPdfFile} className="show-pdf-button">{showPdf ? "Hide Pdf" : "Show Pdf"}</button>
			</div> */}

			
			{
				showPdf ? "" : (
					<Flex justifyContent="end" p={2} color="brand1" fontSize="1.2rem" width="100%" textAlign="center">
						<Box width="8%" p={2}>
							Mat<br />£
						</Box>
						<Box width="8%" p={2}>
							Lab<br />£
						</Box>
						<Box width="8%" p={2}>
							Plant<br />£
						</Box>
						<Box width="8%" p={2} color="brand3">
							Total<br />£
						</Box>
					</Flex>
				)
			}


			<Box>
				<ScheduleDataHits showPdf={showPdf} setShowPdf={setShowPdf} showPdfFile={showPdfFile}  />
			</Box>

		</Flex>:<Box width="100%">
			<PdfViewer />
			</Box>}
	</Flex>
	</Box>
}

export default withViewerLayout(Viewer);
