import moment from "moment";

export function getCookies() {
	let result = {};
	document.cookie
		.split(";")
		.map(pair => pair.trim().split("="))
		.forEach(([name, value]) => {
			result[name] = value
		});
	return result;
}

export function setCookie({ key, value, path, expires }) {
	const cookie = [
		[key, value || ""],
		path ? ["path", path] : null,
		expires ? ["expires", moment(expires).format("ddd, DD MMM YYYY HH:MM:SS Z")] : null
	]
		.filter(c => c)
		.map(([key, value]) => `${key}=${value}`)
		.join("; ");
	document.cookie = cookie;
}
