import { ThemeProvider } from "@emotion/react";
import moment from "moment";
import { useMemo } from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import { Routes, Route, BrowserRouter , redirect , useNavigate} from "react-router-dom";
import Redirect from "./components/Redirect";
import { COOKIE_NAME } from "./constants";
import { UserContext } from "./context";
import { getQuery, parseUserToken } from "./helpers";
import { getCookies, setCookie } from "./helpers/cookies";
// import Account from "./routes/account";
import Admin from "./routes/admin";
import Login from "./routes/login";
import Viewer from "./routes/viewer";
import theme from "./theme";
import axios from "axios";
import { OAUTH_CLIENT_ID, WP_ROOT } from "../src/constants"
import Cookies from "universal-cookie";
import DemoViewer from "./routes/demoViewer";
import ProductList from "./components/subscriber/ProductList";
import Logout from "./routes/logout";



const queryString = getQuery();
const queryStringToken = queryString.get("token");

function App() {

	return <BrowserRouter>
		<WithUserContext >
			<ThemeProvider theme={theme}>
				<AppRoutes />
			</ThemeProvider>
		</WithUserContext>
	</BrowserRouter>
}

function AppRoutes() {

	const { user } = useContext(UserContext);

	return <Routes>
		{!user && <>
			<Route path="/login" element={<Login />} />
			<Route path="/logout" element={<Logout />} />
			<Route path="*" element={<Redirect to="/login" />} />
		    <Route path="/demo-viewer" element={<DemoViewer />} />
		    {/* <Route path="/demo-viewer" element={<DemoViewer />} /> */}

		</>}

		{user && <>
			<Route path="/admin" element={<Admin />} />
			<Route path="/viewer" element={<Viewer />} />
			<Route path="/subscriber/products" element={<ProductList />} />

			{/* <Route path="/account" element={<Account />} /> */}
			<Route path="*" element={<Redirect to="/viewer" />} />
		</>}
	</Routes>
}

function WithUserContext({ children }) {
	const [user, setUser] = useState(null);
	const [token, setToken] = useState(null);
	const [ready, setReady] = useState(false);
	// initialize cookies
	const cookie = new Cookies();

	const navigate = useNavigate();

	const logout = useCallback(() => {

		setUser(null);
		setToken(null);
		setCookie({
			key: COOKIE_NAME,
			value: null
		});
		cookie.remove("nsrm-token");
        navigate('/logout')

	}, []);

	useEffect(() => {
		if (ready) return;
		const cookies = getCookies();
		// If there's a token, use that.
		const token = queryStringToken || cookies[COOKIE_NAME];
		if (token) {
			try {
				// If it lets us parse the token, we're logged in.
				const tokenData = parseUserToken(token);
				setUser(tokenData);
				setToken(token);
				// Make sure the token is saved as a cookie.
				setCookie({
					key: COOKIE_NAME,
					value: token,
					expires: moment(tokenData.exp * 1000),
					path: "/"
				});
			} catch (error) {
				console.warn("Token bad:", token);
				setToken(null);
				// Clear any cookie.
				setCookie({
					key: COOKIE_NAME,
					value: null
				});
				


			}
		}
		// And we're ready.
		setReady(true);

		
	}, [ready]);

	// Form the content of our context.
	const contextState = useMemo(() => ({
		token,
		user,
		setUser,
		logout
	}), [token, user, logout]);

	// Block rendering children until we're ready.
	if (!ready) return "Loading..";

	// And render out the context provider with children.
	return <UserContext.Provider value={contextState}>
		{children}
	</UserContext.Provider>
}

export default App;
