import { useContext } from "react";
import { UserContext } from "../context";

/**
 * Gets the current querystring as an object.
 */
export function getQuery() {
	return new URLSearchParams(window.location.search);
}

export function parseUserToken(token) {
	try {
		const [, body] = token.split(".");
		const bodyData = atob(body);
		return JSON.parse(bodyData);
	} catch (error) {
		throw new Error("Bad token: " + token);
	}
}

export function useIsAdmin() {
	const { user } = useContext(UserContext);
	return user ? user.r.includes("administrator") : false;
}
