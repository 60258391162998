/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import AccountLayout from "./AccountLayout";
import { useNavigate } from "react-router-dom";
import { useIsAdmin } from "../../helpers";

export default function withAdminLayout(Component) {
	return function () {

		const navigate = useNavigate();
		const isAdmin = useIsAdmin();

		// Redirect non-admins away.
		useEffect(() => {
			if (!isAdmin) navigate("/");
		}, [isAdmin, navigate]);

		return <AccountLayout>
			<Component />
		</AccountLayout>
	}
}
