import { Button } from "../../components";
import ProductList from "../../components/admin/ProductList";
import { Box, Flex } from "../../components/FlexBox";
import withAdminLayout from "../../components/layouts/AdminLayout";
import axios from "axios";
import { API_ROOT } from "../../constants";
import { useContext } from "react";
import { UserContext } from "../../context";
import { useState } from "react";

async function renewSchedules(token) {
	try {
		const response = await axios.get(`${API_ROOT}/schedules/renew`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
		return response.data;
	} catch (error) {
		console.error("Error renewing");
		throw new Error(error.message);
	}
}

function Admin() {

	const { token } = useContext(UserContext);
	const [syncing, setSyncing] = useState(false);

	async function onSync() {
		try {
			setSyncing(true);
			await renewSchedules(token);
		} catch (error) {
			console.error("Error syncing.");
		} finally {
			setSyncing(false);
		}
	}

	return <Flex p={2} flexDirection="column" maxWidth="1000px" mx="auto">
		<Box fontSize="2.4rem" fontFamily={0} fontWeight="bold" p={3}>
			Sync Schedule List
		</Box>
		<Box p={2}>
			<Button onClick={onSync} disabled={syncing}>{syncing ? "Syncing.." : "Press to sync"}</Button>
		</Box>
		<ProductList />
	</Flex>
}

export default withAdminLayout(Admin);
