/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { css } from "@emotion/react";

export function Button({ children, bg="brand3", color="#ffffff", fontSize = "1.2rem", ...props }) {
	const theme = useTheme();
	const actualBg = theme.colors[bg] || bg;
	const actualColor = theme.colors[color] || color;
	return <button
		{...props}
		css={css`
			cursor: pointer;
			padding: ${theme.space[2]};
			font-family: ${theme.fonts[0]};
			font-size: ${fontSize};
			font-weight: bold;
			width: 100%;
			border: none;
			color: ${actualColor};
			background-color: ${actualBg};
		`}
	>{children}</button>
}

export function ImgIcon({
	size = 50,
	src,
	onClick,
	cursor,
	iconSize,
	display,
	additionalCss,
	...otherProps
}) {
	let sizeString = typeof size === "number" ? `${size}px` : size;
	return <span
		css={[css`
			background-image: url("${src}");
			background-size: ${iconSize || "contain"};
			background-repeat: no-repeat;
			background-position: center;
			height: ${sizeString};
			width: ${sizeString};
			line-height: ${sizeString};
			text-align: center;
			font-weight: bold;
			display: ${display || "inline-block"};
			cursor: ${cursor ? cursor : (onClick ? "pointer" : "default")};
			font-size: 0.8rem;
		`, additionalCss]}
		onClick={onClick}
		{...otherProps}
	/>
}
